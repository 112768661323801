import React, { useState, Fragment } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import loadable from "@loadable/component"

import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import CategoryNavigation from "../components/CategoryNavigation"
import Seo from "../components/seo"
import BreadCrumbs from "../components/breadcrumbs"
import { Button } from "../components/styles/Buttons"
import Accordion from "../components/accordion"
import Header from "../components/header"
import BrandList from "../components/brandList"
import Footer from "../components/footer";
import Icon from "../components/Icon"
import ShareButtons from "../components/ShareButtons"

import {
  SectionBlue, SectionWrapperSmall } from '../components/styles/Sections';

import { Subheading, Notice } from "../components/styles/Headings";

const PageTemplate = ({ location, data: { page, allWpKampanj } }) => {

  const brands = page.casinolista && page.casinolista.casinon;
  const campaigns = allWpKampanj.nodes;
  const year = new Date().getFullYear();
  const [amount, setAmount] = useState(15);
  const numberOfPosts = page.casinolista.casinon ? brands.length : undefined;
  const faqs = page.faq.faq ? page.faq.faq[0].faqSection : undefined;
  const seoTitle = page.seo ? page.seo.title : undefined;
  const canonicalUrl = page.seo ? "https://svenskaonlinecasinon.se" + page.seo.canonical : undefined;
  const seoMetaDescription = page.seo ? page.seo.metaDesc : undefined;
  const breadCrumbs = page.seo ? page.seo.breadcrumbs : undefined;
  const ogImage = page.featuredImage ? page.featuredImage.node.sourceUrl : page.seo.opengraphImage && page.seo.opengraphImage.link;
  const schema = page.seo.schema.raw;

  const onClick = () => {
    setAmount(numberOfPosts);
  }

  const ToC = loadable(() => import('../components/TableOfContents'), {
    fallback: <div>Laddar innehåll...</div>,
  })

  const Bio = loadable(() => import('../components/bio'), {
    fallback: <div>Laddar innehåll...</div>,
  })

  return (
    <Layout className="blog-post">
      <Seo 
        title={seoTitle} 
        canonical={canonicalUrl}
        description={seoMetaDescription} 
        modified={page.modified} 
        ogImage={ogImage}
        ogType={"website"}
        ogUrl={"https://svenskaonlinecasinon.se/" + page.slug}
        pageUrl={page.sidinformation.pageUrl}
        schema={schema}
      />  
      <article>
        <Header title={page.title} slug={page.slug} description={page.sidinformation.bannerText} campaigns={campaigns} />
        <BreadCrumbs items={breadCrumbs} modified={page.modified} />
        {page.slug !== "kontakta-oss" && brands && (
          <SectionBlue>
            <SectionWrapperSmall>
              <Subheading id="topplista">{page.sidinformation.subtitle} {year}</Subheading>
              <Notice>Alla casinon i vår lista har svensk licens <Icon icon="lock-rounded" size={17} />och är noggrant granskade <Icon icon="check-circle" size={14} /></Notice>
              <CategoryNavigation location={location} pageTitle={page.slug} />
      
              {brands && brands.slice(0, amount).map((item, i) => (
                <BrandList item={item} key={i} count={i} />
              ))}

              {brands && amount < brands.length && (
                <Button className="green large center" onClick={() => onClick()}>Ladda fler</Button>
              )}
            </SectionWrapperSmall>
          </SectionBlue>
        )}
        {!!page.content && (
          <Fragment>
            {page.slug !== "kontakta-oss" && faqs && (
              <SectionBlue>
                <SectionWrapperSmall>
                  <Accordion questionsAnswers={faqs} />
                </SectionWrapperSmall>
              </SectionBlue>
            )}
            {page.slug !== "kontakta-oss" && (
              <ToC headings={page.toc.items} />
            )}
            <section id="main-content" className="containerNarrow">
              <div>
                {parse(page.content)}
                {page.slug !== "kontakta-oss" && (
                  <Fragment>
                    <ShareButtons url={canonicalUrl} title="Dela sida:" />
                    <Bio author={page.author.node} />
                  </Fragment>
                )}
              </div>
            </section>
          </Fragment>
        )}
        <Footer item={page} location={location} pageTitle={page.title} pageUrl={page.sidinformation.pageUrl} pageUrlTitle={page.sidinformation.pageUrlTitle} />
      </article>
    </Layout>
  )
}

export default PageTemplate

export const pageSingleQuery = graphql`
  query PageById(
    $id: String!
  ) {
    page: wpPage(id: { eq: $id}) {
      id
      content
      title
      toc
      slug
      author {
        node {
          name
          description
          uri
          avatar {
            url
          }
        }
      }
      featuredImage {
        node {
        	sourceUrl
        }
      }
      modified(locale: "sv-SE", formatString: "MMMM DD, YYYY")
      casinolista {
        casinon {
          ... on WpPost {
            title
            uri
            slug
            id
            affiliateLink {
              affiliateLink {
                ... on WpAffiliateTracker {
                  id
                  uri
                }
              }
            }
            casinoInformation {
              bonus
              bonuspercent
              description
              description2
              description3
              disclaimer
              ribbon
              fieldGroupName
              freespins
              betalningslosningar
              hemsidaUrl
              lanserades
              loggaBakgrundsfarg
              minimumDeposit
              pageUrl
              pageUrlTitle
              rating
              terms
              uttagstid
              wagering
            }
            categories {
              nodes {
                id
              }
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      height: 120
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
      seo {
        title
        metaDesc
        breadcrumbs {
          text
          url
        }
        schema {
          raw
        }
        canonical
        opengraphSiteName
        opengraphType
        opengraphUrl
        opengraphImage {
          link
        }
      }
      sidinformation {
        bannerText
        subtitle
        pageUrl
        pageUrlTitle
        category {
          id
        }
      }
      faq {
        fieldGroupName
        faq {
          faqSection {
            faqAnswer
            faqQuestion
            fieldGroupName
          }
          fieldGroupName
        }
      }
    }
    allWpKampanj(
      limit: 3 
      sort: { fields: [date], order: DESC}
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        campaign {
          campaignBrand
          brandBackgroundColor
          endDate
          startDate
          campaignAffiliateTracker {
            ... on WpAffiliateTracker {
              id
              affiliateLinks {
                affiliateUrl
              }
              link
            }
          }
        }
        title
        author {
          node {
            name
            avatar {
              url
            }
            authorSocials {
              linkedin
            }
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData (
                  width: 65
                  height: 65
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`
