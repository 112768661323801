import React, { Fragment } from "react"
import parse from "html-react-parser"
import styled from 'styled-components';
import Icon from "../components/Icon";
import { SectionContainerGridFourCols, SectionContainerPaddingSmall } from "./styles/Sections";
import { StaticImage } from "gatsby-plugin-image";
import CampaignComponent from "./CampaignComponent";

class Header extends React.Component {
  constructor () {
    super()
    this.state = {
      isHidden: true
    }
  }

  render() {

    const { title, description, hideToc, isFrontPage, slug, campaigns } = this.props;

    return (
      <HeaderWrapper>
        <div className="image">
        <div className="filter"></div>
          <StaticImage
            className="header-bg" 
            alt="Svenska online casinon" 
            src="../header_image.jpeg" 
            layout="fullWidth"
          />
        </div>
        <header className="global-header">
          {isFrontPage ? (
            <Fragment>
              <h1>Svenska Casino</h1>
              <span className="subheading">Din fullständiga guide för casinon i Sverige!</span>
            </Fragment>
          ) : (
            <h1>{title}</h1>
          )}
          {description && (
            parse(description)
          )}
          {!hideToc && slug !== "kontakta-oss" && campaigns && (
            <Campaigns>
              {campaigns.map((item) => (
                <CampaignComponent item={item} />
              ))}
            </Campaigns>
          )}
        </header>
        {isFrontPage && (
          <Details>
            <SectionContainerPaddingSmall>
              <SectionContainerGridFourCols>
                <div className="column"><Icon icon="lock-rounded" size={24} /> Trygga & Säkra casinon</div>
                <div className="column"><Icon icon="award" size={24} /> Casino med svensk licens</div>
                <div className="column"><Icon icon="thumb-up" size={22} /> Skattefria vinster</div>
                <div className="column"><Icon icon="barometer" size={28} /> Snabba utbetalningar</div>
              </SectionContainerGridFourCols>
            </SectionContainerPaddingSmall>
          </Details>
        )}
      </HeaderWrapper>
    );
  }
}

export default Header;

const HeaderWrapper = styled.div`
  background: var(--color-blue);
  position: relative;
  overflow: hidden;

  .image {
    position: absolute;
    height: 100%;
    width: 100%;

    @media (max-width: 600px) {
      display: none;
    }

    .filter {
      background: linear-gradient(0deg, rgb(22 40 148 / 93%), #152994);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    img {
      bottom: 250px;
      top: unset;
    }
  }

  .global-header {
    max-width: var(--container);
    margin: 0 auto;
    padding: 50px 20px 60px;
    color: var(--color-white);
    position: relative;
    z-index: 2;

    @media (max-width: 600px) {
      padding: 30px 20px 30px;
    }

    h1 {
      color: var(--color-white);
      max-width: 700px;
      margin-top: 0;
      font-size: var(--fontSize-5);
      font-weight: var(--fontWeight-bold);

      @media (max-width: 600px) {
        font-size: 32px;
      }
    }

    .subheading {
      font-size: 22px;
      margin: -20px 0 20px;
      line-height: 1.3;
      font-weight: 500;
      display: block;
      color: var(--color-yellow);
      font-family: var(--fontFamily-sans);
    }

    p {
      font-size: 18px;

      @media (max-width: 600px) {
        font-size: 16px;
        margin-bottom: 20px;
      }
      
      a {
        color: var(--color-yellow);
        text-decoration: none;
      }
    }
  }
`;

const Details = styled.div`
  background: #0e207e;
  color: #f5f5f5;
  font-weight: var(--fontWeight-medium);
  font-size: var(--fontSize-1);
  position: relative;
  z-index: 2;

  @media (max-width: 992px) {
    display: none;
  }

  div {
    font-size: 14px;
  }

  .column {
    align-items: center;
  }

  svg {
    margin-right: 12px;
    margin-top: -1px;
    width: 22px;
    height: 22px;
    color: var(--color-yellow);
  }
`;

const Campaigns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }

  .campaign {
    background: var(--color-blue-dark);
    padding: 12px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    grid-gap: 15px;
    position relative;

   .thumbnail img {
     border-radius: 12px !important;
   }

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.3;
      display: block;
      margin-bottom: 3px;
      color: #f5f5f5;
    }

    .badge {
      color: var(--color-yellow);
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
    }

    p {
      margin: 0;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.35;
      color: #f5f5f5;
    }

    .time {
      font-size: 11px;
      position: absolute;
      top: 8px;
      right: 8px;
      border-radius: 12px;
      font-weight: 600;
      color: #f5f5f5;
      padding: 2px 10px;
      background: #dcdbd83d;
    }
  }
`;