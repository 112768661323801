import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const CampaignComponent = ({ item }) => {

  const featuredImage = {
    data: item.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: item.featuredImage?.node?.altText || ``,
  }

  const currentDate = new Date();
  const [day, month, year] = item.campaign.endDate ? item.campaign.endDate.split('/') : "2";
  const endDate = new Date(+year, +month - 1, +day);
  const campaignLength = Math.abs(endDate - currentDate);
  let campaignEnds = campaignLength / (1000 * 60 * 60 * 24);

  return (
    <Link to={item.uri} className="campaign">
       {featuredImage?.data && (
        <div className="thumbnail">
          <GatsbyImage
            image={featuredImage.data}
            alt={featuredImage.alt}
            style={{ 
              height: 65,
              width: 65,
              borderRadius: 12
            }}
          />
        </div>
      )}
      <div className="content">
        <span>{item.campaign.campaignBrand}</span>
        <p>{item.title}</p>
      </div>
      {item.campaign.startDate && item.campaign.endDate && (
        <div className="time">
          {Math.ceil(campaignEnds)} dagar kvar
        </div>
      )}
    </Link>
  )
}

export default CampaignComponent;